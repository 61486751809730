<template>
    <form class="theme-form" @submit.prevent="login">
        <h4>Sign in to account</h4>
        <p>Enter your email & password to login</p>
        <div class="form-group">
            <label class="col-form-label">Email Address</label>
            <input class="form-control" type="email" v-model.trim="data.user.email" required placeholder="Test@gmail.com" />
        </div>
        <div class="form-group">
            <label class="col-form-label">Password</label>
            <div class="form-input position-relative">
                <input class="form-control" :type="data.active?'password':'text'" name="password" v-model.trim="data.user.password"  required placeholder="*********" />
                <div class="show-hide"><span :class="data.active ? 'show' : 'hide'"  @click.prevent="data.active = !data.active"></span></div>
            </div>
        </div>
        <div class="form-group mb-0 mt-4">
            <button type="submit" class="btn btn-primary btn-block w-100" :disabled="data.processing || data.user.password.trim() == '' || data.user.email.trim() == '' " @click="login">
                {{ (data.processing) ? 'Logging in...' : 'Sign in'}}
            </button>
        </div>
        <p class="mt-4 mb-0 text-center">
            Don't have account?
            <router-link class="ms-2" tag="a" :to="{name: 'register'}">
                Create Account
            </router-link>
        </p>
        <p class="mt-2 mb-0 text-center">
            <router-link class="ms-2" tag="a" :to="{name: 'auth.recovery'}">
                Forgot my password
            </router-link>
        </p>
    </form>
</template>

<script setup>
    import { reactive } from 'vue';
    import { useAuthStore } from "@/modules/auth/store";
    import { useRouter ,useRoute} from 'vue-router';
    import Swal from "sweetalert2";
    import env from '@/data/env.json';
    import axios from 'axios';

    const store = useAuthStore();
    const route = useRoute();
    const router = useRouter()
    const data = reactive({
        user: {
            email: '',
            password: ''
        },
        active: true,
        processing: false,
        errors:{}
    });

    const login = async () => {
        data.processing = true;
        store.clearErrors();
        try {
            axios.defaults.withCredentials = true;
            axios.defaults.withXSRFToken = true;
            console.log(data.user);
            await axios.get(env.url+'/sanctum/csrf-cookie')
            await axios.post(env.url+'/api/login', data.user).then( (response) => {
                store.storeUser(response.data);         
                const redirectPath = typeof route.query.redirect  !== 'undefined'  ? route.query.redirect : '' ;
                if(typeof route.query.redirect  !== 'undefined' ){
                    router.push({path: redirectPath});
                }
                else{
                    router.push({name : 'home'});
                }
                
            }).catch((error) => {
                Swal.fire({
                    title: 'Error!',
                    text: error.response.data.message,
                    icon: 'error'
                });
            });
        } catch (error) {
        } finally {
            data.processing = false;
        }
        
    }
</script>
