<template>
  <router-link to="/">
    <img
      class="img-fluid for-light"
      src="/img/logo.png"
      alt=""
      style="max-width: 50px;"
    /><img
      class="img-fluid for-dark"
      src="/img/hitower_lightbg.svg"
      alt=""
    />
  </router-link>
</template>
<script>

  export default {
    name: 'Logo',
  };
</script>
