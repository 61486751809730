import Body from "@/components/body.vue";
import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import Search from "../views/Search.vue";
import ParameterTableDisplay from "@/modules/wageTaxCalculation/views/ParameterTableDisplay.vue";

export default {
    path: '/',
    component: Body,
    meta: {
        middleware: 'auth',
        requiresAuth: true
    },
    beforeEnter: isVerifyTokenGuard,
    children: [
        { path: '/wage-tax-calculations', component: Search, name: 'wageTaxCalculations', },
        { path: '/wage-tax-calculations/parameter-table-display', component: ParameterTableDisplay, name: 'wageTaxCalculations.parameterTableDisplay', }
    ]

}