import { createRouter, createWebHistory } from "vue-router"
import authRouter from '@/modules/auth/router';
import homeRouter from '@/modules/home/router';
import { checkAuth } from '@/guards/verifyTokenGuard.js';
import exceptionRouter from '@/modules/exceptions/router';
import wageRouter from "@/modules/wageTaxCalculation/router";
import demoRouter from "@/modules/Demo/router";

const routes = [
    { ...homeRouter },
    { ...authRouter },
    { ...wageRouter },
    { ...demoRouter },
    
    // exceptionRouter always has to go at the end
    { ...exceptionRouter }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
        checkAuth().then(() => {
            next();
        }).catch(() => {
            next({ name: 'login' , query: { redirect: to.fullPath } });
        });
    } else {
        next();
    }
})

export default router

export { routes }