import AuthLayout from '../layouts/AuthLayout.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';
import SendEmailPassword from "@/modules/auth/views/SendEmailPassword.vue";

export default {
    path: '',
    children: [
        {
            path: '/login',
            component: AuthLayout,
            meta: {
                middleware: 'guest'
            },
            children: [
                { path: '', component: Login, name: 'login' },
                { path: '/register', component: Register, name: 'register' },
                { path: '/recovery', component: ForgotPassword, name: 'auth.recovery' },
                { path: '/recovery/send', component: SendEmailPassword, name: 'auth.recovery-send' },
                { path: '/reset-password/:token/:email', component: ResetPassword, name: 'auth.reset-password' }
            ]
        }
    ]
};