
<template>
    
    <div class="container-fluid">
        <Breadcrumbs title="Demo" main="PaySlip Demo"/>
        <div class="row starter-main">
            <div class="col-12">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5>Payslip</h5>
                    </div>
                    <div class="d-flex align-items-center  flex-column flex-md-row">
                        <div class="card-body col-12 col-md-8">
                            <p>Explore how our payroll system works. Use this page to generate sample payslips for different salary scenarios</p>
                            <p>Choose a scenario and click to see a generated payslip with typical deductions and taxes applied.</p>
                        </div>
                        <picture class="col-md-4 col-12 overflow-hidden m-3">
                            <img src="/img/exampleImg.jpg" alt="demo-payslip">
                        </picture>
                    </div>
                    <div class="d-flex flex-column flex-md-row">
                        <div class="col-lg-4 p-3 col-12 col-md-6" v-for="(datum, key) in dataPayslip" :key="key">
                            <div class="card card-body text-center">
                                <h5 class="card-title">{{ datum.title }}</h5>
                                <p class="card-text">{{ datum.request.employee.firstName +' '+ datum.request.employee.lastName }}</p>
                                <p class="card-text text-capitalize">{{ datum.request.period.type }}</p>
                                <button type="button" class="btn btn-primary float-center" @click="generatePayslip(key)" :disabled="(typeof buttonActive[key] !== 'undefined')">
                                    {{ (typeof buttonActive[key] !== 'undefined') ? 'Generating...' : 'Generate' }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import dataPayslip from "@/data/demoPayslip.json";
import api from '@/guards/api';

const buttonActive = ref([]);

const generatePayslip = (id) => {
    buttonActive.value[id] = true;

    api.post('/api/getEmployeePayslip', dataPayslip[id].request).then((response) => {
        const pdfBlob = base64ToBlob(response.data.payslip_url, 'application/pdf');
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
    }).catch((error) => {
        
    }).finally(() => {
        delete buttonActive.value[id];
    });
}

function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
}
</script>

