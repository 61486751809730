<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
<!--      <img class="b-r-10" src="@/assets/images/dashboard/profile.png" alt="" />-->
      <div class="media-body">
        <span>{{ (store.user !== '') ? store.user.user.name : '' }}</span>
        <p class="mb-0 font-roboto">
          Admin <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li @click="logout">
        <vue-feather type="log-in"></vue-feather><span>Log out</span>
      </li>
    </ul>
  </li>
</template>

<script setup>
import { onMounted } from "vue";
import { useAuthStore } from "@/modules/auth/store";
import router from "@/router";
const store = useAuthStore();

const logout = () => {
    store.clearStoredData();
    router.push({name: 'login'});
}
</script>
