import Body from "@/components/body.vue";
import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import PaySlip from "../views/PaySlipDemo.vue" ;

export default {
    path: '/',
    component: Body,
    meta: {
        middleware: 'auth',
        requiresAuth: true
    },
    beforeEnter: isVerifyTokenGuard,
    children: [
       
        { path: '/demo', component: PaySlip, name: 'demo', }
    ]    
}