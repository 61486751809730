<template>
    <form class="theme-form" @submit.prevent="validateMatchPassword">
        <h4>Reset password</h4>
        <p>Enter your new credentials to change your password</p>
        <div class="form-group">
            <label class="col-form-label">Email Address</label>
            <input class="form-control" type="email" required v-model="data.user.email" readonly/>
        </div>
        <div class="mt-3 form-group">
            <label class="col-form-label">New password</label>
            <div class="form-input position-relative">
                <input class="form-control" :type="data.active ? 'password' : 'text'" min="8" v-model="data.user.password" placeholder="*********" required />
                <div class="show-hide"><span :class="data.active ? 'show' : 'hide'"  @click.prevent="data.active = !data.active"></span></div>
            </div>
        </div>
        <div class="mt-3 form-group">
            <label class="col-form-label">Confirm new password</label>
            <div class="form-input position-relative">
                <input class="form-control" :type="data.active ? 'password' : 'text'" min="8" id="inputPasswordConfirmation" v-model="data.user.password_confirmation" placeholder="*********" required />
                <div class="show-hide"><span :class="data.active ? 'show' : 'hide'"  @click.prevent="data.active = !data.active"></span></div>
            </div>
        </div>
        <div class="form-group mb-0 mt-4">
            <button type="submit" class="btn btn-primary btn-block w-100" :disabled="data.processing">
                {{ (data.processing) ? 'Changing password...' : 'Change password'}}
            </button>
        </div>
    </form>
</template>

<script setup>
import {onMounted, reactive} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import Swal from "sweetalert2";

import env from '@/data/env.json';
import axios from 'axios';

const route = useRoute();
const router = useRouter();
const data = reactive({
    user: {
        email: '',
        token: '',
        password: '',
        password_confirmation: ''
    },
    active: true,
    processing: false
});

const validateMatchPassword = () => {
    let confirm_password = document.getElementById('inputPasswordConfirmation');
    if(data.user.password !== data.user.password_confirmation) {
        confirm_password.setCustomValidity("Passwords don't match");
    } else {
        confirm_password.setCustomValidity('');
        recovery()
    }
}

const recovery = async () => {
    data.processing = true;
    try {
        await axios.get(env.url+'/sanctum/csrf-cookie')
        await axios.post(env.url+'/api/account/password-reset', data.user).then( (response) => {
            Swal.fire({
                title: 'Success!',
                text: 'Password reset successfully.',
                icon: 'success'
            });
            router.push({name: 'login'});
        }).catch((error) => {
            Swal.fire({
                title: 'Error!',
                text: error.response.data.message,
                icon: 'error'
            });
        });
    } catch (error) {
    } finally {
        data.processing = false;
    }
}

onMounted(() => {
    data.user.email = route.params.email;
    data.user.token = route.params.token;
});
</script>