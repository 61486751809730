import Body from "@/components/body.vue";
import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import Home from "@/modules/home/views/Home.vue";

export default {
    path: '/',
    component: Body,
    children: [
        {
            path: '',
            name: 'home',
            meta: {
                requiresAuth: true
            },
            component: Home,
        },
    ],
}