
<template>
    
    <div class="container-fluid">
        <Breadcrumbs title="Payroll Tax Table Search" main="Wage Tax Calculation"/>

        <div class="row starter-main">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5>Wage tax settings</h5>
                        <button type="button" class="btn btn-primary btn-lg ms-auto me-2" @click="toggleModal">
                            Import
                        </button>
                    </div>
                    <form @submit.prevent="getFilterResult">
                        <div class="card-body">
                            <div class="row">
                                <p class="mb-5">The Dutch tax authority provides tables that can be used as input to know how high the wage tax should be. These tables are updated each calendar year and can be consulted to find the correct amount of wage tax to deduct from an employee's gross wage. When you want to know the employee's wage tax, you need to know 5 things. Use this page to load the files into the system. <a href="/downloads/wit_wk_nl_std_20240101.xlsx" download>Example Here</a></p>
                               <div v-if="modalActive">
                                <Modal
                                    :modalActive="modalActive"
                                    :modalTitle ="modalTitle"
                                    @close-modal="toggleModal"
                                >
                                    <Import></Import>
                                </Modal>
                                </div>
                            </div>
                            <div class="row mb-5">
                                <div class="col mb-3">
                                    <label class="d-block">Type</label>
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                        <input type="radio" class="btn-check" id="type-standard" autocomplete="off" v-model="data.filters.type" value="standard" checked>
                                        <label class="btn btn-outline-primary" for="type-standard">Standard</label>

                                        <input type="radio" class="btn-check" id="type-special" autocomplete="off" v-model="data.filters.type" value="special">
                                        <label class="btn btn-outline-primary" for="type-special">Special</label>
                                    </div>
                                </div>
                                <div class="col mb-3">
                                    <label>Color</label>
                                    <select class="form-select" v-model="data.filters.color" required>
                                        <option value="">Choose a color</option>
                                        <option v-for="(color, key) in filterValues.color" :value="color" :key="key">{{ color }}</option>
                                    </select>
                                </div>
                                <div class="col mb-3">
                                    <label>Year</label>
                                    <select class="form-select" v-model="data.filters.year" required>
                                        <option value="">Choose a year</option>
                                        <option v-for="(year, key) in filterValues.year" :value="year" :key="key">{{ year }}</option>
                                    </select>
                                </div>
                                <div class="col mb-3">
                                    <label>Period</label>
                                    <select class="form-select" :disabled="data.filters.type==='special'" v-model="data.filters.period" required>
                                        <option value="">Choose a period</option>
                                        <option v-for="(period, key) in filterValues.period" :value="period" :key="key">{{ period }}</option>
                                    </select>
                                </div>
                                <div class="col mb-3 justify-content-end align-content-end text-center pb-1">
                                    <button type="submit" class="btn btn-primary btn-lg" :disabled="data.processing">
                                        {{ data.processing ? 'Please wait...' : 'Filter' }}
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <div class="" v-if="originalDataResponse.length">
                                        <label class="">{{data.filterTitle}} filter:</label>
                                        <input type="text" id="filter-data" class="form-control" v-model="data.filterText" v-on:input="filterData()">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <ag-grid-vue
                                        :rowData="rowData"
                                        :columnDefs="colDefs"
                                        style="height: 500px"
                                        class="ag-theme-quartz"
                                        @grid-ready="onGridReady"
                                    >
                                    </ag-grid-vue>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from 'vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from "ag-grid-vue3";
import api from '@/guards/api';
import Import from "@/modules/wageTaxCalculation/views/Import.vue";
import { useEventListener } from '@vueuse/core';
import Modal from '@/components/modal.vue'



let originalDataResponse = {};
const filterData = ()=>{
    const query = data.value.filterText;
    const field = data.value.filters.type != 'standard'? 'anual_salary':'wage';

    if(!query){
        rowData.value = originalDataResponse;
    }else{
        rowData.value = originalDataResponse.filter(e=>{
            return parseFloat(e[field]) <= parseFloat(query);
        }).sort((a,b)=>{
            return parseFloat(a[field]) > parseFloat(b[field]) ? -1 : 1;
        });
    }
}

const rowData = ref([]);
const colDefs = ref([]);
const data = ref({
    filters: {
        type: 'standard',
        color: '',
        period: '',
        year: ''
    },
    processing: false,
    filterTitle: '',
    filterText: '',
});


const filterValues = ref({
    color: [],
    period: [],
    year: []
})

const getFilterValues = () => {
    api.get('/api/getTaxCreditsEmployeesFilters/'+data.value.filters.type).then( (response) => {
        filterValues.value = response.data;
    }).catch( (error) => {
    })
};

const getFilterResult = () => {
    data.value.processing = true;
    api.get('/api/getTaxCreditsEmployees', {
        params: data.value.filters
    }).then( (response) => {
        originalDataResponse = response.data.rows;
        colDefs.value = response.data.columns;
        data.value.filterTitle = data.value.filters.type != 'standard'? 'Annual Salary':'Wage'
        filterData();

    }).catch( (error) => {
    }).finally(() => {
        data.value.processing = false;
    })
}
const modalActive = ref(null);
const modalTitle = ("Tax credits for employees");

const toggleModal = () => {
    modalActive.value = !modalActive.value;
}
const clearFiltersAndGrid = () => {
    data.value.filters.color = '';
    data.value.filters.period = '';
    data.value.filters.year = '';
    data.value.filterText = '';
    rowData.value = [];
    colDefs.value = [];
    originalDataResponse = []
};

onMounted(() => {
    getFilterValues()
})

watch(() => data.value.filters.type, (newValue) => {
    clearFiltersAndGrid()
    getFilterValues()
});


const gridApi = ref();

const onGridReady = (params) => {
    gridApi.value = params.api;
};

const handleFileUploadSuccess = () => {
    clearFiltersAndGrid();
};

useEventListener('file-uploaded', handleFileUploadSuccess);

</script>

