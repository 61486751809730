import { useAuthStore } from "@/modules/auth/store";
import axios from "axios";
import env from '@/data/env.json';
import router from '@/router';

export const checkAuth = () => {
    return new Promise((resolve, reject) => {
        const storeAuth = useAuthStore();
        if (storeAuth.auth) {
              const headers = {
                      'Authorization': 'Bearer ' + storeAuth.user.access_token
                }   ;
                axios.post(env.url + "/api/validate_token", {}, { headers })
                .then((response) => {
                    storeAuth.storeUser(response.data);
                    resolve();
                })
                .catch((error) => {
                    storeAuth.clearStoredData();
                    reject(error);
                });
        } else {
            reject(new Error('No se ha autenticado'));
        }
    });
    
}
