<template>

    <div class="container-fluid">
      
        <div class="row starter-main">
            <div class="col-sm-12">
                <div class="">
                    <!-- <div class="card-header">
                        <h5>Tax credits for employees</h5>
                    </div> -->
                    <div class="card-body">
                        <form @submit.prevent="submitFile">
                            <p class="mb-5">Please upload your tax credits configuration file, remembering that it must comply with the format and fields specified in the example file.</p>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 mb-3">
                                    <label>Color table</label>
                                    <select name="" class="form-select" v-model="data.form.tableColor" required>
                                        <option value="">Choose a color</option>
                                        <option v-for="(text, value) in dataDisplay.colors" :value="value">{{ text }}</option>/
                                    </select>
                                </div>
                                <div class="col-sm-12 col-md-6 mb-3">
                                    <label>Type table</label>
                                    <select name="" class="form-select" v-model="data.form.tableType" required>
                                        <option value="">Choose a type</option>
                                        <option v-for="(text, value) in dataDisplay.types" :value="value">{{ text }}</option>/
                                    </select>
                                </div>

                                <div class="col-sm-12 col-md-6 mb-3">
                                    <label>Period</label>
                                    <select name="" class="form-select" v-model="data.form.periodType" :disabled="data.form.tableType==='special'" required>
                                        <option value="">Choose a period</option>
                                        <option v-for="(text, value) in dataDisplay.periods" :value="value">{{ text }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-12 col-md-6 mb-3">
                                    <label>Year</label>
                                    <input type="number" class="form-control" placeholder="Insert a year" min="2024" v-model="data.form.year" required>
                                </div>

                                <div class="col-12 mb-3">
                                    <label for="formFile" class="form-label">File</label>
                                    <input class="form-control" type="file" id="formFile" ref="file" @change="uploadFile">
                                </div>
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary float-end" :disabled="data.processing">
                                        {{ data.processing ? 'Saving...' : 'Save payslip settings' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import waxTaxCalculation from "@/data/waxTaxCalculation.json";
import api from "@/guards/api";
import Swal from "sweetalert2";

const dataDisplay = waxTaxCalculation;

const data = reactive({
    form: {
        tableColor: '',
        tableType: '',
        periodType: '',
        year: '',
        tableFile: ''
    },
    processing: false
})

const file = ref(null)

const uploadFile = (event) => {
    data.form.tableFile = event.target.files[0];
}

const submitFile = () => {
    data.processing = true;
    const formData = new FormData();
    for (let key in data.form) {
        formData.append(key, data.form[key]);
    }

    api.post("/api/TaxCreditsEmployees", formData).then( (response) => {
        data.form = {
            tableColor: '',
            tableType: '',
            periodType: '',
            year: '',
            tableFile: ''
        };
        file.value = null;
        document.getElementById('formFile').value = null;
        Swal.fire({
                title: 'Success!',
                text: 'File was uploaded successfully.',
                icon: 'success'
            });
        const event = new CustomEvent('file-uploaded');
        window.dispatchEvent(event);
    }).catch( (error) => {
    }).finally(() => {
        data.processing = false;
    })
}
</script>