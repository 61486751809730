<template>
<form class="theme-form" @submit.prevent="register">
    <h4>Create your account</h4>
    <p>Enter your personal details to create account</p>
    <div class="form-group">
        <label class="col-form-label pt-0">Your Name</label>
        <input class="form-control" v-model="data.user.name" type="text" name="name" required placeholder="First name" />
    </div>
    <div class="form-group">
        <label class="col-form-label">Email Address</label>
        <input class="form-control" v-model="data.user.email" type="email" name="email" required placeholder="Test@gmail.com" />
    </div>
    <div class="form-group mb-3">
        <label class="col-form-label">Password</label>
        <div class="form-input position-relative">

            <input class="form-control" v-model="data.user.password" :type="data.active?'password':'text'" name="password" required placeholder="*********" />
            <div class="show-hide"><span :class="data.active ? 'show' : 'hide'" @click.prevent="data.active = !data.active"> </span></div>
        </div>
    </div>
    <div class="form-group mb-0">
        <button type="submit" class="btn btn-primary btn-block w-100 mt-4" :disabled="data.processing">
            {{ data.processing ? "Please wait" : "Create account" }}
        </button>
    </div>
    <p class="mt-4 mb-0 text-center">
        Already have an account?
        <router-link class="ms-2" tag="a" :to="{name: 'login'}">
            Login
        </router-link>
    </p>
</form>
</template>

<script setup>
import { reactive } from "vue";
import env from '@/data/env.json';
import { useAuthStore } from "@/modules/auth/store";
import { useRouter } from 'vue-router';
//import router from '@/modules/auth/router';
import axios from "axios";
import Swal from "sweetalert2";

const store = useAuthStore();
const router = useRouter()
const data = reactive({
    user: {
        name: "",
        email: "",
        password: "",
        withLogin: true
    },
    processing: false,
    active: true
})

const register = async () => {
    data.processing = true;
    store.clearErrors();
    try {
        await axios.get(env.url+'/sanctum/csrf-cookie')
        await axios.post(env.url+'/api/register', data.user).then( (response) => {
            login()
        }).catch((error) => {
            Swal.fire({
                title: 'Error!',
                text: error.response.data.message,
                icon: 'error'
            });
        });
    } catch (error) {
    } finally {
        data.processing = false;
    }
}

const login = async () => {
    data.processing = true;
    store.clearErrors();
    try {
        await axios.get(env.url+'/sanctum/csrf-cookie')
        await axios.post(env.url+'/api/login', data.user).then( (response) => {
            store.storeUser(response.data);
            router.push({name: 'home'});
        }).catch((error) => {
            Swal.fire({
                title: 'Error!',
                text: error.response.data.message,
                icon: 'error'
            });
        });
    } catch (error) {
    } finally {
        data.processing = false;
    }
}
</script>
