<template>
    <form class="theme-form" @submit.prevent="recovery">
        <h4>Recovery my account</h4>
        <p>Enter your email to recover your account</p>
        <div class="form-group">
            <label class="col-form-label">Email Address</label>
            <input class="form-control" type="email" v-model="data.user.email" required placeholder="Test@gmail.com" />
        </div>
        <div class="form-group mb-0 mt-4">
            <button type="submit" class="btn btn-primary btn-block w-100" :disabled="data.processing">
                {{ (data.processing) ? 'Sending email...' : 'Send email'}}
            </button>
        </div>
        <p class="mt-4 mb-0 text-center">
            Don't have account?
            <router-link class="ms-2" tag="a" :to="{name: 'register'}">
                Create Account
            </router-link>
        </p>
        <p class="mt-2 mb-0 text-center">
            Already have an account?
            <router-link class="ms-2" tag="a" :to="{name: 'login'}">
                Login
            </router-link>
        </p>
    </form>
</template>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import Swal from "sweetalert2";

import env from '@/data/env.json';
import axios from 'axios';

const router = useRouter()
const data = reactive({
    user: {
        email: ''
    },
    active: true,
    processing: false
});

const recovery = async () => {
    data.processing = true;
    try {
        await axios.get(env.url+'/sanctum/csrf-cookie')
        await axios.post(env.url+'/api/account/recovery', data.user).then( (response) => {
            router.push({name: 'auth.recovery-send'});
        }).catch((error) => {
            Swal.fire({
                title: 'Error!',
                text: error.response.data.message,
                icon: 'error'
            });
        });
    } catch (error) {
    } finally {
        data.processing = false;
    }
}
</script>