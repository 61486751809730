<template>
  <Teleport to="body">
      <div class="modal fade show" v-show="modalActive" id="exampleModalCenter" tabindex="-1" aria-labelledby="exampleModalCenter"
          aria-modal="true" role="dialog" style="display: block;">
          <div class="modal-dialog modal-dialog-centered"  v-if="modalActive"  role="document">
              <div class="modal-content"  v-if="modalActive" ref="target">
                  <div class="modal-header">
                      <h5 class="modal-title">{{ modalTitle }}</h5><button class="btn-close"   @click="$emit('close-modal')" type="button" data-bs-dismiss="modal"
                          aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                     <slot></slot>
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-backdrop" v-show="modalActive">
      </div>
  </Teleport>
  
  </template>

  <script setup>
  import { ref } from 'vue'
  import { onClickOutside } from '@vueuse/core';

  const target = ref(null)

  const emit = defineEmits(["close-modal"]);
  onClickOutside(target, event => {
  
    const modalError = document.querySelector('.swal2-icon.swal2-error.swal2-icon-show');

    if (modalError != null) {
      return  
    }
    else {
      emit("close-modal")
    }
  });

  defineProps({
    modalActive: {
      type: Boolean,
      default: false,
    },
    modalTitle :{
      type : String,
    },
  });
  
  </script>
  
