import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import 'bootstrap/dist/js/bootstrap.bundle'
import '@/assets/scss/app.scss'
import VueFeather from 'vue-feather';
import en from './locales/en.json';
import { defaultLocale, localeOptions } from './constants/config';
import Breadcrumbs from './components/bread_crumbs';
import Env from './data/env.json';
import axios from "axios";
import {createPinia} from "pinia";
// axios.defaults.withCredentials = true;
axios.defaults.baseURL = Env.url;

const pinia = createPinia();
const messages = { en: en };
const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale;

const app = createApp(App)
    .use(router)
    .use(store)
    .use(pinia)
    .component(VueFeather.name, VueFeather)
    .component('Breadcrumbs', Breadcrumbs);
app.config.globalProperties.$axios = axios;
app.mount('#app');
