
import axios from 'axios';
import env from '@/data/env.json';
import { useAuthStore } from "@/modules/auth/store";
import Swal from "sweetalert2";
import router from '@/router';
import { useRouter } from 'vue-router';
import { checkAuth } from './verifyTokenGuard';

// Create an instance of axios
const api = axios.create({
    baseURL: env.url,
    timeout: 10000, // Optional: Set a timeout for requests
});

// Request Interceptor
api.interceptors.request.use(
    async (config) => {
        try {
            // Add authorization token or other request modifications
            await checkAuth().then(() => {

            }).catch((error) => {
                if (error.response) {
                    Swal.fire({
                        title: error.response.data.message,
                        icon: "error",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    }).then((result) => {
                        router.push({ path: 'login', query: { redirect: router.currentRoute.value.fullPath } });
                    });
                }
                reject(error);
            });
            const storeAuth = useAuthStore();
            const token = storeAuth.user.access_token;

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
);


// Response Interceptor
api.interceptors.response.use(
    (response) => {

        return response;
    },
    (error) => {
        if (error.response) {
            const notification = {
                title: 'Error!',
                text: error.response.data.message,
                icon: 'error'
            };
            switch (error.response.status) {
                case 401:

                    Swal.fire({
                        title: error.response.data.message,
                        icon: "error",
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonText: "OK",

                    }).then((result) => {
                        router.push({ path: 'login', query: { redirect: router.currentRoute.value.fullPath } });
                    });
                    break;
                case 422:
                case 500:

                    Swal.fire(notification);
                    break;

                default:
                    Swal.fire(notification);
            }
        } else if (error.request) {

        } else {

        }
        return Promise.reject(error);
    }
);

export default api;
