<template>
    <div class="container-fluid">
        <Breadcrumbs />

        <div class="row starter-main">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Salary Package</h5>
                    </div>
                    <div class="card-body">
                        <p>A salary package refers to the total compensation a person receives from their employer for their work. It encapsulates various components that make up the total remuneration. These components can vary widely depending on the employer, the industry, and the level of the position. A salary package may include both positive components, such as compensations, and negative components, like taxes and insurance payments.</p>
                    </div>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h5>Wage Tax Table</h5>
                    </div>
                    <div class="card-body">
                        <p>The Dutch tax authority provides tables that can be used as input to know how high the wage tax should be. These tables are updated each calendar year and can be consulted to find the correct amount of wage tax to deduct from an employee's gross wage. When you want to know the employee's wage tax, you need to know 5 things:</p>
                        <ul>
                            <li>Period wage</li>
                            <li>Tax table color (depends on the employment status of the employee)</li>
                            <li>Standard or Special table (depends on the type of remuneration we're calculating the wage tax for)</li>
                            <li>Year</li>
                            <li>Period type (weekly, 4 weeks, monthly, quarterly)</li>
                        </ul>
                        <p>With this data, you look for the corresponding table and look for the period wage in the first column (rounded down). Then, depending on the employee's specific situation, you choose the column and look for the amount of tax to be deducted from the period wage. This tables can only be used by humans making wage tax calculations, not by automated systems. This is why in the Salary Package Module the wage tax is calculated by using the methods provided by the Dutch tax authority specifically for automated payroll administration, the wage tax tables are provided as a way to validate the calculations.</p>
                        <div class="col-12 text-center mt-5">
                            <router-link :to="{name: 'wageTaxCalculations'}" class="btn btn-primary">
                                Go to section
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>