
<template>

    <div class="container-fluid">
        <Breadcrumbs title="Automatic payroll tax parameters" main="Wage Tax Calculation"/>

        <div class="row starter-main">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header d-flex align-items-center">
                        <h5>Parameters table display</h5>
                    </div>
                    <form @submit.prevent="getData">
                        <div class="card-body">
                            <div class="row">
<!--                                <p class="mb-5">Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam, nesciunt dicta facere ullam dolores molestiae provident eos ut nemo modi reprehenderit at debitis tenetur voluptates ratione earum? Velit, dolores molestias?.</p>-->
                            </div>
                            <div class="row mb-5">
                                <div class="col mb-3">
                                    <label>Color</label>
                                    <select class="form-select" v-model="data.filters.color" required>
                                        <option value="">Choose a color</option>
                                        <option v-for="(color, id) in filterValues.color" :value="id">{{ color }}</option>
                                    </select>
                                </div>
                                <div class="col mb-3">
                                    <label>Jurisdiction</label>
                                    <select class="form-select" v-model="data.filters.jurisdiction" required>
                                        <option value="">Choose a jurisdiction</option>
                                        <option v-for="(jurisdiction, id) in filterValues.jurisdiction" :value="id">{{ jurisdiction }}</option>
                                    </select>
                                </div>
                                <div class="col mb-3">
                                    <label>Redirect situation</label>
                                    <select class="form-select" v-model="data.filters.redirect_situation" required>
                                        <option value="">Choose a redirect situation</option>
                                        <option v-for="(redirect_situation, id) in filterValues.redirect_situation" :value="id">{{ redirect_situation }}</option>
                                    </select>
                                </div>
                                <div class="col mb-3 justify-content-end align-content-end text-center pb-1">
                                    <button type="submit" class="btn btn-primary btn-lg" :disabled="data.processing">
                                        {{ data.processing ? 'Please wait...' : 'Filter' }}
                                    </button>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <ag-grid-vue
                                        :rowData="rowData"
                                        :columnDefs="colDefs"
                                        style="height: 500px"
                                        class="ag-theme-quartz"
                                    >
                                    </ag-grid-vue>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { AgGridVue } from "ag-grid-vue3";
import api from '@/guards/api';


const rowData = ref([]);
const colDefs = ref([]);
const data = ref({
    filters: {
        color: '',
        jurisdiction: '',
        redirect_situation: ''
    },
    processing: false,
    filterTitle: '',
    filterText: '',
});


const filterValues = ref({
    color: [],
    jurisdiction: [],
    redirect_situation: []
});

const getFilterValues = () => {
    api.get('/api/getWageTaxParameterFilters').then( (response) => {
        filterValues.value = response.data;
    }).catch( (error) => {
    })
};

const getData = () => {
    data.value.processing = true;
    api.get('/api/getWageTaxParameterData', {
        params: data.value.filters
    }).then( (response) => {
        colDefs.value = response.data.columns;
        rowData.value =   response.data.rows;
    }).catch( (error) => {
       
    }).finally(() => {
        data.value.processing = false;
    });
};


onMounted(() => {
    getFilterValues()
});

</script>

